import HTTP from './HttpService'

var Application = {

  archiveApplication: async function (applicationId) {
    var archiveResponse = {};

    await HTTP.http().put('/application/archive/' + applicationId)
      .then(function(result) {
        archiveResponse = result.data;
      })
      .catch(function(error) {
        console.log('Archive application error: ' + error);
      });

    return archiveResponse;
  },

  createApplication: async function (application) {
    var createResponse = {};

    await HTTP.http().post('/application/create', application)
      .then(function(result) {
        createResponse = result.data;
      })
      .catch(function(error) {
        console.log('Create application error: ' + error);
      });

    return createResponse;
  },

  resubmitSimpleNexus: async function (applicationId) {
    let application = {};
    await HTTP.http().put('/application/' + applicationId + '/simplenexus')
      .then(function(result) {
        application = result.data;
      })
      .catch(function (error) {
        console.log('submit SimpleNexus error: ' + error);
      });

    return application;
  },

  assignLoanOfficer: async function (applicationId, userId) {
    let response = {};

    await HTTP.http().put('/application/' + applicationId + '/user/' + userId)
        .then(function(result) {
          response = result.data;
        })
        .catch(function(error) {
          console.log('Assign Loan Officer error: ' + error);
        });

    return response;
  },

  emailRateQuoteForId: async function (applicationId, borrowerMsg) {
    let emailResponse = {};

    await HTTP.http(true).put('/application/' + applicationId + '/email', borrowerMsg)
        .then(function(result) {
          emailResponse = {
            status: true,
            result: result.data
          }
        })
        .catch(function(error) {
          console.log('Email Rate Quote error: ' + error);

          emailResponse = {
            status: false,
            result: error.response.data
          }
        });

    return emailResponse;
  },

  deleteApplication: async function (applicationId) {
    var deleteResponse = {};

    await HTTP.http().delete('/application/delete/' + applicationId)
      .then(function(result) {
        deleteResponse = result.data;
      })
      .catch(function(error) {
        console.log('Delete application error: ' + error);
      });

    return deleteResponse;
  },

  findApplications: async function (applicationFilter, page, size) {
    var appData = [];

    await HTTP.http(this).post('/application/query', applicationFilter, {
      params: {
        page: page,
        size: size
      }
    })
      .then(function(result) {
        appData = result.data;
      })
      .catch(function(error) {
        console.log('Load applications error: ' + error);
      });

    return appData;
  },

  getApplication: async function (applicationId) {
    let application = null;

    await HTTP.http().get("/application/" + applicationId)
      .then(function(result) {
        application = result.data;
      })
      .catch(function(error) {
        console.log('Load single application error: ' + error);
      });

    return application;
  },

  setStatus: async function (applicationId, status) {
    let application = null;

    await HTTP.http().put("/application/" + applicationId + "/status/" + encodeURIComponent(status))
        .then(function(result) {
          application = result.data;
        })
        .catch(function(error) {
          console.log('status update failed: ' + error);
        })
    return application;
  },

  setSecondStatus: async function (applicationId, secondStatus) {
    let application = null;

    await HTTP.http().put("/application/" + applicationId + "/secondstatus/" + encodeURIComponent(secondStatus))
        .then(function(result) {
          application = result.data;
        })
        .catch(function(error) {
          console.log('second status update failed: ' + error);
        })
    return application;
  },

  setLeadSource: async function (applicationId, leadSource) {
    let application = null;

    await HTTP.http().put("/application/" + applicationId + "/leadSource/" + encodeURIComponent(leadSource))
      .then(function(result) {
        application = result.data;
      })
      .catch(function(error) {
        console.log('status update failed: ' + error);
      })
    return application;
  },

  setPromotionCode: async function (applicationId, promotionCode) {
    let application = null;

    if (promotionCode === undefined) {
        promotionCode = 'null';
    }

    await HTTP.http().put("/application/" + applicationId + "/promotion/" + promotionCode)
        .then(function(result) {
          application = result.data;
        })
        .catch(function(error) {
          console.log('status update failed: ' + error);
        })
    return application;
  },

}

export default Application;
