import HTTP from './HttpService'

var Loan = {

  getLoan: async function(loanId) {
    var loan = {};

    await HTTP.http().get('/loan/' + loanId)
      .then(function(response) {
        if (response) {
          loan = response.data;
        }
      })
      .catch(function(error) {
        console.log('Load loan error: ' + error);
      });

      return loan;
  },

  createLoan: async function (loanData) {
    var responseLoan = {};

    await HTTP.http().post('/loan/create', loanData)
      .then(function(result) {
        responseLoan = result.data;
      })
      .catch(function(error) {
        console.log('Create loan error: ' + error);
      });

    return responseLoan;
  },

  deleteLoan: async function (loanId) {
    var deleteResponse = {};

    await HTTP.http().delete('/loan/delete/' + loanId)
      .then(function(result) {
        deleteResponse = result.data;
      })
      .catch(function(error) {
        console.log('Delete loan error: ' + error);
      });

    return deleteResponse;
  },

  updateLoan: async function (loanData) {
    var responseLoan = {};

    await HTTP.http().post('/loan/update', loanData)
      .then(function(result) {
        responseLoan = result.data;
      })
      .catch(function(error) {
        console.log('Update loan error: ' + error);
      });

      return responseLoan;
  }

}

export default Loan;
