import Store from '@/store'
import HTTP from './HttpService'

var User = {

  changeMyPassword: async function (newPassword) {
    var response = {};

    await HTTP.http().post('/user/changepassword', newPassword)
      .then(function(result) {
        response = result.data;
        response.status = result.status;
      })
      .catch(function(error) {
        console.log('Change password error: ' + error + "; status was: " + error.status);
        response.status = error.status;
      });

      return response;
  },

  createUser: async function (user) {
    let mappedUser = {
      firstName: user.firstName,
      lastName: user.lastName,
      username: user.username,
      password: user.password,
      email: user.email,
      loanOfficerTitle: user.loanOfficerTitle,
      phoneNumber: user.phoneNumber,
      nmlsNumber: user.nmlsNumber,
      corporateNmlsNumber: user.corporateNmlsNumber,
      jungoUserId: user.jungoUserId,
      teamLeadId: user.teamLeadId,
      admin: user.admin,
      active: user.active
    }

    let responseUser = {};

    await HTTP.http().post('/user/create', mappedUser)
      .then(function(result) {
        responseUser = result.data;
      })
      .catch(function(error) {
        console.log('Create user error: ' + error);
      });

    return responseUser;
  },

  deleteUser: async function (userId) {
    var deleteResponse = {};

    await HTTP.http().delete('/user/delete/' + userId)
      .then(function(result) {
        deleteResponse = result.data;
      })
      .catch(function(error) {
        console.log('Delete user error: ' + error);
      });

    return deleteResponse;
  },

  getUser: async function(userId) {
    var userRecord;

    await HTTP.http().get('/user/' + userId)
      .then(function(response) {
        if (response) {
          userRecord = response.data;
        }
      })
      .catch(function(error) {
        console.log('Load user information error: ' + error);
      });

      return userRecord;
  },

  getMyUserInfo: async function() {
    var userInfo;

    await HTTP.http().get('/user')
      .then(function(response) {
        if (response) {
          userInfo = response.data;
          Store.dispatch('setUserInfo', { payload: userInfo });
        }
      })
      .catch(function(error) {
        console.log('Load user information error: ' + error);
      });

      return userInfo;
  },

  getUserList: async function() {
    var userList = [];

    await HTTP.http().get('/user/userlist')
      .then(function(response) {
        if (response) {
          userList = response.data;
        }
      })
      .catch(function(error) {
        console.log('Get user list error: ' + error);
      });

      return userList;
  },

  updateUser: async function (user) {
    let mappedUser = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      username: user.username,
      password: user.password,
      email: user.email,
      loanOfficerTitle: user.loanOfficerTitle,
      phoneNumber: user.phoneNumber,
      nmlsNumber: user.nmlsNumber,
      corporateNmlsNumber: user.corporateNmlsNumber,
      jungoUserId: user.jungoUserId,
      teamLeadId: user.teamLeadId,
      admin: user.admin,
      active: user.active
    }

    let responseUser = {};

    await HTTP.http().post('/user/update', mappedUser)
      .then(function(result) {
        responseUser = result.data;
      })
      .catch(function(error) {
        console.log('Update user error: ' + error);
      });

      return responseUser;
  },

  updateMyInfo: async function (userData) {
    let mappedUserData = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
    }

    var updatedUserData = {};

    await HTTP.http().post('/user/updatemyinfo', mappedUserData)
      .then(function(result) {
        updatedUserData = result.data;
        Store.dispatch('setUserInfo', { payload: updatedUserData });
      })
      .catch(function(error) {
        console.log('Update profile information error: ' + error);
      });

      return updatedUserData;
  }

}

export default User;
