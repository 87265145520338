<template>
  <div v-if="showNotesBlock">

  <div v-if="showNotes" class="card mt-4" id="notes">
    <div class="card-header">
      <div class="row custom-card-sm-header">
        <div class="col-auto mr-auto" v-cloak>
          <p class="medium">Notes</p>
        </div>
        <div class="col-auto custom-cursor-pointer" @click="addNote()">
          <i class="fa fa-plus-circle mr-2" aria-hidden="true" />
          <span class="medium">Add Note</span>
        </div>
      </div>
    </div>

    <table class="table table-sm mb-0">
      <thead class="custom-bg-med">
        <tr class="d-flex">
          <th class="col-1 small">Date</th>
          <th class="col-2 small">Created By</th>
          <th class="col-8 small">Note</th>
          <th class="col-1 small"></th>
        </tr>
      </thead>
      <tbody v-cloak>
        <tr v-for="(note, index) in notes" :key="note.id" class="d-flex">
          <td class="custom-fg-dark col-1 small">{{formatDate(note.created, "MM/DD/YYYY")}}</td>
          <td class="custom-fg-dark col-2 small">{{note.createdBy}}</td>
          <td class="custom-fg-dark col-8">
            <MultilineTextInputField :key="note.body" :passedData="{name: 'body', index: index, value: note.body}" @input="changeFromComponent"/>
          </td>
          <td class="col-1 small custom-cursor-pointer text-right">
            <i class="fa fa-trash" aria-hidden="true" @click="deleteNote(note)" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="row mt-2">
    <div class="col">
      <a
          href="#"
          class="custom-fg-dark"
          @click="showNotes = !showNotes"
      >{{(showNotes) ? "hide notes" : "show notes"}}</a>
    </div>
  </div>

  </div>
</template>

<script>
import lodash from 'lodash'
import moment from 'moment'
import MultilineTextInputField from '@/components/util/MultilineTextInputField'
import Note from '@/services/NoteService'

export default {
  name: 'notes',
  components: {
    MultilineTextInputField
  },
  created: async function () {
    if (!this.applicationId || this.applicationId === 0) {
      this.showNotesBlock = false;
    }

    this.notes = await Note.getNotes(this.applicationId);

    if (!this.notes || this.notes.length <= 0) {
      this.showNotes = false;
    }
  },
  data: function () {
    return {
      notes: [],
      showNotes: true,
      showNotesBlock: true
    }
  },
  methods: {
    addNote: async function() {
      var note = {
        applicationId: this.applicationId,
        body: "",
      };

      note = await Note.createNote(note);
      this.notes.push(note);
    },
    changeFromComponent: async function(returnData) {
      var name = returnData.name;
      var idx = returnData.index;
      var note = this.notes[idx];
      var newValue = returnData.newValue;

      note[name] = newValue;
      this.notes[idx] = await Note.updateNote(note);
    },
    deleteNote: function(note) {
      Note.deleteNote(note.id);
      this.notes = lodash.without(this.notes, note);
    },
    editNote: function(id, newBody) {
      var idx  = lodash.findIndex(this.notes, {id: id});
      var note = this.notes[idx];

      note.body = newBody;
      Note.updateNote(note);
    },
    formatDate: function (date, format) {
      return moment(date.substring(0, 10)).format(format);
    },
    selectNoteText(target) {
      console.log("Value is: " + target.innerText + " selected start: " + target.selectionStart + " and end: " + target.selectionEnd);
    },
  },
  props: ['applicationId'],
}
</script>
