<template>
  <div class="container">
    <h5>{{pageTitle}}</h5>
    <p>
      <span>
        [ <router-link :to="{name: 'Applications'}" class="custom-fg-dark">Return to Application Listing</router-link> ]
      </span>
      <span v-if="passedApplicationId > 0">
        [ <router-link :to="{name: 'ApplicationsEdit', params: {applicationId: passedApplicationId}}" class="custom-fg-dark">Edit Application</router-link> ]
      </span>
    </p>

    <div id="spinnerModal">
      <SpinnerModal v-if="showSpinnerModal">
        <p slot="body">Searching...</p>
      </SpinnerModal>
    </div>

    <div id="confirmModal">
      <ConfirmModal v-if="showConfirmModal" @cancel="showConfirmModal=false" @confirm="attachApplication">
        <p slot="header">{{confirmModalHeader}}</p>
        <p slot="body">{{confirmModalBody}}</p>
      </ConfirmModal>
    </div>

    <div id="okayModal">
      <OkayModal v-if="showOkayModal" @okay="showOkayModal=false">
        <p slot="header">{{okayModalHeader}}</p>
        <p slot="body">{{okayModalBody}}</p>
      </OkayModal>
    </div>

    <div id="SearchResultModal">
      <SearchResultModal v-if="showSearchResultModal" :passedData="passedData" @close="showSearchResultModal=false" />
    </div>

    <div id="ApplicationModal">
      <ApplicationModal v-if="showApplicationModal" :passedData="passedData" @create="createApplication" @cancel="showApplicationModal=false" />
    </div>

    <Notes :applicationId="passedApplicationId" />

    <div class="row mt-2">

      <div class="col-3">
        <h5 class="card-header">Search Criteria</h5>
        <form @submit.prevent="search">
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="loanPurpose" class="form-control-sm custom-label-thin-margin">Loan Purpose</label>
              <span class="input-help">
                <select class="custom-select custom-select-sm" id="loanPurpose" v-model="loan.loanPurpose" @change="changeKeepingLOC()">
                  <option v-for="(loanPurpose) in loanPurposes" :key="loanPurpose.id" :value="loanPurpose.name">{{loanPurpose.name}}</option>
                </select>
                <small id="loanPurposeHelpBlock" class="ml-2 form-text text-danger" v-if="quoteRun && loan.loanPurpose == null">Please select a loan purpose</small>
              </span>
            </div>
          </div>
          <div class="form-row" v-if="showPurchasePrice">
            <div class="form-group col-md-12">
              <label for="purchasePrice" class="form-control-sm custom-label-thin-margin text-dark">Purchase Price</label>
              <span class="input-help">
              <input
                type="text"
                class="form-control form-control-sm"
                id="purchasePrice"
                placeholder="$"
                v-model="property.purchasePrice"
                @keyup="changePropertyAmount('purchasePrice')"/>
                <small id="purchasePriceHelpBlock" class="ml-2 form-text text-danger" v-if="quoteRun && showPurchasePrice && convertStringToNumber(property.purchasePrice) <= 0">Please enter a purchase price</small>
              </span>
            </div>
          </div>
          <div class="form-row" v-if="showPropertyValue">
            <div class="form-group col-md-12">
              <label for="propertyValue" class="form-control-sm custom-label-thin-margin text-dark">Property Value</label>
              <span class="input-help">
              <input
                type="text"
                class="form-control form-control-sm"
                id="propertyValue"
                placeholder="$"
                v-model="property.value"
                @keyup="changePropertyAmount('value')"/>
                <small id="propertyValueHelpBlock" class="ml-2 form-text text-danger" v-if="quoteRun && showPropertyValue && convertStringToNumber(property.value) <= 0">Please enter a property value</small>
              </span>
            </div>
          </div>
          <div class="form-row" v-if="showCashOutAmount">
            <div class="form-group col-md-12">
              <label for="cashOutAmount" class="form-control-sm custom-label-thin-margin text-dark">Cash Out Amount</label>
              <span class="input-help">
              <input
                type="text"
                class="form-control form-control-sm"
                id="cashOutAmount"
                placeholder="$"
                v-model="refinanceLoan.cashOutAmount"
                @keyup="changeRefinanceAmount('cashOutAmount')"/>
                <small id="cashOutAmountHelpBlock" class="ml-2 form-text text-danger" v-if="quoteRun && showCashOutAmount && convertStringToNumber(refinanceLoan.cashOutAmount) <= 0">Please enter the cash out amount</small>
              </span>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="loanAmount" class="form-control-sm custom-label-thin-margin text-dark">Total Loan Amount</label>
              <span class="input-help">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="loanAmount"
                  placeholder="$"
                  v-model="loan.amount"
                  @keyup="changeLoanAmount()"/>
                <small id="ltv" class="ml-2 form-text text-info">{{calculateLTV()}}</small>
                <small id="loanAmountHelpBlock" class="ml-2 form-text text-danger" v-if="quoteRun && convertStringToNumber(loan.amount) <= 0">Please enter a total loan amount</small>
              </span>
            </div>
          </div>
          <div class="form-row" v-if="showExistingLOC">
            <div class="form-group col-md-12">
              <label for="loc" class="form-control-sm custom-label-thin-margin text-dark">Existing LOC: </label>
              <input type="checkbox" id="loc" v-model="refinanceLoan.loc" />
            </div>
          </div>
          <div class="form-row" v-if="showExistingLOC && refinanceLoan.loc">
            <div class="form-group col-md-12">
              <label for="locAmount" class="form-control-sm custom-label-thin-margin text-dark">LOC Balance</label>
              <span class="input-help">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="locAmount"
                  placeholder="$"
                  v-model="refinanceLoan.locAmount"
                  @blur="changeRefinanceAmount('locAmount')"/>
                <small id="locAmountHelpBlock" class="ml-2 form-text text-danger" v-if="quoteRun && refinanceLoan.loc && convertStringToNumber(refinanceLoan.locAmount) <= 0">Please enter a LOC balance</small>
              </span>
            </div>
          </div>
          <div class="form-row" v-if="showExistingLOC && refinanceLoan.loc">
            <div class="form-group col-md-12">
              <label for="loc" class="form-control-sm custom-label-thin-margin text-dark">Keeping LOC: </label>
              <br />
              <span class="input-help">
                <div v-for="(trueFalse) in trueFalseValues" :key="trueFalse.id" class="form-check form-check-inline">
                  <input type="radio" v-model="refinanceLoan.keepingLoc" name="keepingLoc" :value="trueFalse.id" @change="changeKeepingLOC()"/>
                  <label class="form-check-label form-control-sm" for="keepingLoc">{{trueFalse.value}}</label>
                </div>
                <small id="keepingLocHelpBlock" class="ml-2 form-text text-danger" v-if="quoteRun && refinanceLoan.keepingLoc == null">Please select Yes or No</small>
              </span>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="loanPurpose" class="form-control-sm custom-label-thin-margin">Loan Type</label>
              <span class="input-help">
                <select class="custom-select custom-select-sm" id="loanType" v-model="loan.loanType">
                  <option v-for="(loanType) in loanTypes" :key="loanType.id" :value="loanType.name">{{loanType.name}}</option>
                </select>
                <small id="loanTypeHelpBlock" class="ml-2 form-text text-danger" v-if="quoteRun && loan.loanType == null">Please select a loan type</small>
              </span>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="loanPurpose" class="form-control-sm custom-label-thin-margin">Amortization Type</label>
              <span class="input-help">
                <select class="custom-select custom-select-sm" id="amortizationType" v-model="loan.amortizationType">
                  <option value="">All types</option>
                  <option v-for="(amortizationType) in amortizationTypes" :key="amortizationType.id" :value="amortizationType.name">{{amortizationType.name}}</option>
                </select>
                <small id="amortizationTypeHelpBlock" class="ml-2 form-text text-danger" v-if="quoteRun && loan.amortizationType == null">Please select an amortization type</small>
              </span>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="loanPurpose" class="form-control-sm custom-label-thin-margin">Lock Period</label>
              <span class="input-help">
                <select class="custom-select custom-select-sm" id="lockPeriod" v-model="loan.lockPeriod">
                  <option value="">All types</option>
                  <option v-for="(lockPeriod) in lockPeriods" :key="lockPeriod.id" :value="lockPeriod.name">{{lockPeriod.name}}</option>
                </select>
                <small id="lockPeriodHelpBlock" class="ml-2 form-text text-danger" v-if="quoteRun && loan.lockPeriod == null">Please select a lock period</small>
              </span>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="zip" class="form-control-sm custom-label-thin-margin text-dark">Zip Code</label>
              <span class="input-help">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="zip"
                  placeholder="Enter Zip Code"
                  v-model="propertyAddress.zip"
                  @blur="changePropertyAddressZip()"/>
                <small id="zipHelpBlock" class="ml-2 custom-fg-dark" v-if="validZip(propertyAddress.zip)">{{cityStateZipCounty}}</small>
                <small id="zipHelpBlock" class="ml-2 form-text text-danger" v-if="quoteRun && !validZip(propertyAddress.zip)">Please enter a valid zip</small>
              </span>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="propertyType" class="form-control-sm custom-label-thin-margin">Property Type</label>
              <span class="input-help">
                <select class="custom-select custom-select-sm" id="propertyType" v-model="property.propertyType">
                  <option v-for="(propertyType) in propertyTypes" :key="propertyType.id" :value="propertyType.name">{{propertyType.name}}</option>
                </select>
                <small id="propertyTypeHelpBlock" class="ml-2 form-text text-danger" v-if="quoteRun && property.propertyType == null">Please select a property type</small>
              </span>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="propertyUse" class="form-control-sm custom-label-thin-margin">Property Use</label>
              <span class="input-help">
                <select class="custom-select custom-select-sm" id="propertyUse" v-model="property.propertyUse">
                  <option v-for="(propertyUse) in propertyUses" :key="propertyUse.id" :value="propertyUse.name">{{propertyUse.name}}</option>
                </select>
                <small id="propertyUseHelpBlock" class="ml-2 form-text text-danger" v-if="quoteRun && property.propertyUse == null">Please select a property use</small>
              </span>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="creditRating" class="form-control-sm custom-label-thin-margin">Credit Rating</label>
              <span class="input-help">
                <select class="custom-select custom-select-sm" id="creditRating" v-model="primaryBorrower.creditRating">
                  <option v-for="(creditRating) in creditRatings" :key="creditRating.id" :value="creditRating.name">{{creditRating.name}}</option>
                </select>
                <small id="creditRatingHelpBlock" class="ml-2 form-text text-danger" v-if="quoteRun && primaryBorrower.creditRating == null">Please select a credit rating</small>
              </span>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="taxesAndInsurance" class="form-control-sm custom-label-thin-margin">Taxes and Insurance</label>
              <br />
              <span class="input-help">
                <div v-for="(trueFalse) in trueFalseValues" id="taxesAndInsurance" :key="trueFalse.id" class="form-check form-check-inline">
                  <input type="radio" v-model="primaryBorrower.taxesAndInsurance" name="taxesAndInsurance" :value="trueFalse.id"/>
                  <label class="form-check-label form-control-sm" for="taxesAndInsurance">{{trueFalse.value}}</label>
                </div>
                <small id="taxesAndInsuranceHelpBlock" class="ml-2 form-text text-danger" v-if="quoteRun && primaryBorrower.taxesAndInsurance == null">Please select Yes or No</small>
              </span>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="purchasePrice" class="form-control-sm custom-label-thin-margin text-dark">Credit</label>
              <span class="input-help">
              <input
                  type="text"
                  class="form-control form-control-sm"
                  id="credit"
                  placeholder="$0"
                  v-model="loan.credit"
                  @keyup="changeCredit()"/>
              </span>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="promotionCode" class="form-control-sm text-dark">Promotion Code</label>
              <select class="custom-select custom-select-sm" id="promotionCode" v-model="application.promotionCode">
                <option
                  v-for="(promotionCode) in promotionCodes"
                  :key="promotionCode.id"
                  :value="promotionCode.promotionCode">
                  {{promotionCodeValue(promotionCode)}}
                </option>
              </select>
            </div>
          </div>

          <div class="text-right mt-4">
            <button class="btn btn-primary" @click.prevent="search()">Search</button>
          </div>
        </form>
      </div>

      <div class="col-9">
        <h5 class="card-header">Loan Search Results</h5>

        <div class="container mt-2">
          <div class="row">
            <div class="col-auto mr-auto" v-cloak v-if="rowCount > 0">
              <p class="custom-fg-dark mt-2">{{rowCount}} Loan Product{{(rowCount > 1) ? "s" : ""}} Returned</p>
            </div>
            <div class="col-auto mr-auto" v-cloak v-if="rowCount <= 0 && quoteRun">
              <p class="custom-fg-dark">No rows found - please check your search criteria</p>
            </div>
            <div class="col-auto" v-if="rowCount > 0">
              <input id="searchResults" placeholder="Filter Results" type="text" class="form-control" aria-label="Filter Results" v-model="searchTerm">
            </div>
          </div>

        </div>
        <table class="table table-sm table-hover mb-0 quote-table">
          <thead class="custom-bg-med custom-sticky-header sticky-top">
            <tr>
              <th scope="col"><a href="#" @click="sort('rate')">Rate</a></th>
              <th scope="col">APR</th>
              <th scope="col">Fee</th>
              <th scope="col"><a href="#" @click="sort('amortizationTerm')">Term</a></th>
              <th scope="col">Lock</th>
              <th scope="col">Lender</th>
              <th scope="col">Product Name</th>
              <th scope="col">P&I</th>
              <th scope="col">MI</th>
              <th scope="col">Total Payment</th>
              <th scope="col">Price</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody v-cloak>
            <tr
              v-for="(searchResult, index) in filteredResult"
              v-on:dblclick="showSearchResultDetail(searchResult)"
              :key="searchResult.id"
              :class="{'table-success': searchResult.productId === application.productId && searchResult.rate === loan.interestRate}">
              <template v-if="searchResult.amortizationTerm === 'empty'">
              <td colspan="11"><hr></td>
              </template>
              <template v-else>
              <td class="custom-fg-dark text-right" >{{formatPercentage(searchResult.rate)}}</td>
              <td class="custom-fg-dark text-right" >{{formatPercentage(searchResult.apr)}}</td>
              <td class="custom-fg-dark text-right" >{{formatCurrency(searchResult.fee)}}</td>
              <td class="custom-fg-dark text-right" >{{searchResult.amortizationTerm}}</td>
              <td class="custom-fg-dark text-right" >{{searchResult.lockPeriod}}</td>
              <td class="custom-fg-dark" >{{searchResult.investor}}</td>
              <td class="custom-fg-dark" >{{formatProductNamePreview(searchResult.productName)}}</td>
              <td class="custom-fg-dark text-right">{{formatCurrency(searchResult.principalAndInterest)}}</td>
              <td class="custom-fg-dark text-right">{{formatCurrency(searchResult.monthlyMortgageInsurance, true)}}</td>
              <td class="custom-fg-dark text-right" >{{formatCurrency(searchResult.totalPayment, true)}}</td>
              <td class="custom-fg-dark text-right" >{{searchResult.price}}</td>
              <td ><i class="fa fa-paperclip customer-fg-dark mr-2 custom-cursor-pointer" aria-hidden="true" @click="confirmAttachApplication(index)"/></td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>

    </div>

  </div>
</template>

<script>
import numeral from 'numeral'

import Address from '@/services/AddressService'
import Application from '@/services/ApplicationService'
import Borrower from '@/services/BorrowerService'
import County from '@/services/CountyService'
import Loan from '@/services/LoanService'
import LoanSearch from '@/services/LoanSearchService'
import PickDetail from '@/services/PickDetailService'
import PromotionCode from '@/services/PromotionCodeService'
import Property from '@/services/PropertyService'
import RefinanceLoan from '@/services/RefinanceLoanService'

import ApplicationModal from '@/components/modals/ApplicationModal'
import ConfirmModal from '@/components/modals/ConfirmModal'
import OkayModal from '@/components/modals/OkayModal'
import SearchResultModal from '@/components/modals/SearchResultModal'
import SpinnerModal from '@/components/modals/SpinnerModal'
import Notes from '@/components/notes/Notes'
import ApplicationHistory from "@/services/ApplicationHistoryService";

export default {
  name: "Quotes",
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  },
  data: function () {
    return {
      application: {},
      cityStateZipCounty: "",
      loan: {},
      responseApplicationHistory: {},
      passedApplicationId: 0,
      primaryBorrower: {},
      property: {},
      propertyAddress: {},
      refinanceLoan: {},
      zipInfo: {},

      sortColumn: 'amortizationTerm',
      reverse: false,

      confirmModalHeader: "",
      confirmModalIndex: -1,
      confirmModalBody: "",
      okayModalHeader: "",
      okayModalBody: "",
      quoteRun: false,
      showApplicationModal: false,
      showConfirmModal: false,
      showOkayModal: false,
      showSpinnerModal: false,
      showSearchResultModal: false,

      creditRatings: [],
      loanPurposes: [],
      passedData: {},
      promotionCodes: [],
      propertyTypes: [],
      propertyUses: [],
      lockPeriods: [],
      amortizationTypes: [],
      loanTypes: [],
      searchResults: [],
      searchTerm: "",
      trueFalseValues: [
        {
          id: "false",
          value: "No",
        },
        {
          id: "true",
          value: "Yes",
        },
      ],
    }
  },
  components: {
    ApplicationModal,
    ConfirmModal,
    OkayModal,
    Notes,
    SearchResultModal,
    SpinnerModal
  },
  computed: {
    filteredResult: function() {
      var filtered = [];
      filtered = this.searchResults.filter(result => {
        var rateStr = result.rate.toString();
        var termStr = result.amortizationTerm.toString();
        var lockStr = result.lockPeriod.toString();

        return (
          result.investor.toLowerCase().match(this.searchTerm.toLowerCase()) ||
          result.productName.toLowerCase().match(this.searchTerm.toLowerCase()) ||
          rateStr.match(this.searchTerm) ||
          termStr.match(this.searchTerm) ||
          lockStr.match(this.searchTerm)
        );
      });
      var newSearchResults = [];
      let currentGroupValue = null;
      var i = 0;
      let localSortColumn = this.sortColumn;
      filtered.filter(function(result) {
        let currentGroupKey = localSortColumn === '' ? 'amortizationTerm' : localSortColumn;
        if (i === 0) {
          currentGroupValue = result[currentGroupKey];
        }
        if (result[currentGroupKey] !== currentGroupValue && i > 0) {
            currentGroupValue = result[currentGroupKey];
            let emptyRecord = {
              amortizationTerm: "empty",
              amortizationType: "empty",
              apr: 0,
              fee: 0,
              totalThirdPartyFee: null,
              filter: false,
              investor: "empty",
              lockPeriod: 0,
              monthlyMortgageInsurance: 0,
              price: 0,
              principalAndInterest: 0,
              productId: 0,
              productName: "empty",
              rate: 0,
              recommended: false,
              searchId: "empty",
              totalPayment: 0,
            };
            newSearchResults.push(emptyRecord);
        }
        newSearchResults.push(result);
        i++;
      });
      return newSearchResults;
    },
    pageTitle: function() {
      var title;

      if (this.passedApplicationId > 0) {
        title = "Quote for " + this.primaryBorrower.firstName + " " + this.primaryBorrower.lastName + " - " + "Application #" + this.passedApplicationId;
      } else {
        title = "New Quote";
      }

      return title;
    },
    rowCount: function () {
      return this.searchResults.length;
    },
    showCashOutAmount: function() {
      return (this.loan.loanPurpose != null && this.loan.loanPurpose.toLowerCase().includes("cash out"));
    },
    showExistingLOC: function() {
      return (this.loan.loanPurpose != null && this.loan.loanPurpose.toLowerCase().includes("refinance"));
    },
    showPropertyValue: function() {
      return (this.loan.loanPurpose !== 'Purchase');
    },
    showPurchasePrice: function () {
      return (this.loan.loanPurpose === 'Purchase');
    },
  },
  created: async function () {
    // Get data from our APIs, but only if we have an application id
    this.passedApplicationId = this.$route.params.applicationId;
    if (this.passedApplicationId > 0) {
      this.loadApplicationData(this.passedApplicationId);
    }

    // Pick lists
    this.creditRatings = await PickDetail.getPickListDetail("creditrating");
    this.loanPurposes = await PickDetail.getPickListDetail("loanpurpose");
    this.promotionCodes = await PromotionCode.getPromotionCodes();
    this.propertyTypes = await PickDetail.getPickListDetail("propertytype");
    this.propertyUses = await PickDetail.getPickListDetail("propertyuse");
    this.loanTypes = await PickDetail.getPickListDetail('mortgagetype')
    this.lockPeriods = await PickDetail.getPickListDetail('lockperiod')
    this.amortizationTypes = await PickDetail.getPickListDetail('amortizationtype')

    // Blank value for promotions code
    var blankPromotionCode = {
      value: "",
    };

    this.promotionCodes.unshift(blankPromotionCode);
  },
  methods: {
    attachApplication: async function() {
      let searchResult = this.filteredResult[this.confirmModalIndex];

      if (this.passedApplicationId > 0) {
        this.showConfirmModal = false;

        this.responseApplicationHistory = await ApplicationHistory.createApplicationHistory(this.passedApplicationId);

        this.application.investor = searchResult.investor;
        this.application.product = searchResult.productName;
        this.application.productId = searchResult.productId;
        this.application.result = JSON.stringify(this.searchResults);

        this.primaryBorrower = await Borrower.updateBorrower(this.primaryBorrower);

        this.loan.amount = this.convertStringToNumber(this.loan.amount);
        this.loan.amortizationType = searchResult.amortizationType;
        this.loan.credit = this.convertStringToNumber(this.loan.credit)
        this.loan.numberOfMonths = this.calculateNumberOfMonths(searchResult.amortizationTerm);
        this.loan.interestRate = searchResult.rate;
        this.loan.fee = searchResult.fee;
        this.loan.totalThirdPartyFee = searchResult.totalThirdPartyFee;
        this.loan.apr = searchResult.apr;
        this.loan.mortgagePayment = searchResult.totalPayment;

        // If we have refinance loan information from form
        if (this.refinanceLoan != null) {
          this.refinanceLoan.locAmount = this.convertStringToNumber(this.refinanceLoan.locAmount);
          this.refinanceLoan.cashOutAmount = this.convertStringToNumber(this.refinanceLoan.cashOutAmount);
          // If refinance loan already exists
          if (this.refinanceLoan.id != null) {
            this.refinanceLoan = await RefinanceLoan.updateRefinanceLoan(this.refinanceLoan);
          }
          // Create new refinance loan
          else {
            this.refinanceLoan = await RefinanceLoan.createRefinanceLoan(this.refinanceLoan);
            this.loan.refinanceLoanId = this.refinanceLoan.id;
          }

          this.refinanceLoan.locAmount = this.formatCurrency(this.refinanceLoan.locAmount);
          this.refinanceLoan.cashOutAmount = this.formatCurrency(this.refinanceLoan.cashOutAmount);
        }

        this.loan = await Loan.updateLoan(this.loan);
        this.loan.amount = this.formatCurrency(this.loan.amount);

        this.property.purchasePrice = this.convertStringToNumber(this.property.purchasePrice);
        this.property.value = this.convertStringToNumber(this.property.value);
        this.property = await Property.updateProperty(this.property);
        this.property.purchasePrice = this.formatCurrency(this.property.purchasePrice);
        this.property.value = this.formatCurrency(this.property.value);

        this.propertyAddress.city = this.zipInfo.city;
        this.propertyAddress.stateId = this.zipInfo.stateId;
        this.propertyAddress.countyId = this.zipInfo.countyId;
        this.propertyAddress = await Address.updateAddress(this.propertyAddress);

        this.routeToEdit(this.passedApplicationId);
      } else {
        this.passedData = {
          cashOutAmount: this.refinanceLoan.cashOutAmount,
          creditRating: this.primaryBorrower.creditRating,
          apr: searchResult.apr,
          fee: searchResult.fee,
          totalThirdPartyFee: searchResult.totalThirdPartyFee,
          investor: searchResult.investor,
          keepingLoc: this.refinanceLoan.keepingLoc,
          loanAmount: this.loan.amount,
          loanPurpose: this.loan.loanPurpose,
          loanImpounds: this.loan.loanImpounds,
          credit: this.loan.credit,
          loc: this.refinanceLoan.loc,
          locAmount: this.refinanceLoan.locAmount,
          numberOfMonths: this.calculateNumberOfMonths(searchResult.amortizationTerm),
          product: searchResult.productName,
          productId: searchResult.productId,
          promotionCode: this.application.promotionCode,
          purchasePrice: this.property.purchasePrice,
          propertyType: this.property.propertyType,
          propertyUse: this.property.propertyUse,
          rate: searchResult.rate,
          result: JSON.stringify(this.searchResults),
          totalPayment: searchResult.totalPayment,
          value: this.property.value,
          zip: this.propertyAddress.zip,
          taxesAndInsurance: this.primaryBorrower.taxesAndInsurance,
          loanType: this.loan.loanType,
          lockPeriod: searchResult.lockPeriod
              ? searchResult.lockPeriod
              : null,
          amortizationType: searchResult.amortizationType
              ? searchResult.amortizationType
              : null,

          dataWasPassed: true,
        };

        this.showApplicationModal = true;
      }
    },
    calculateLTV: function() {
      var loanAmount = this.convertStringToNumber(this.loan.amount);
      var value = 0.0;

      if (this.loan.loanPurpose === 'Purchase') {
        value = this.convertStringToNumber(this.property.purchasePrice);
      } else {
        value = this.convertStringToNumber(this.property.value);
      }

      if (value > 0) {
        return "LTV: " + numeral(loanAmount / value).format('0.00%');
      } else {
        return "LTV: Undefined";
      }
    },
    calculateNumberOfMonths: function(amortizationTerm) {
      var years = this.convertStringToNumber(amortizationTerm);
      var months = years * 12;

      return months;
    },
    changeKeepingLOC: function() {
      if (this.refinanceLoan.keepingLoc != null && this.refinanceLoan.keepingLoc === 'false' && this.loan.loanPurpose !== 'Refinance Cash Out') {
        this.okayModalHeader = "Paying Off Line of Credit";
        this.okayModalBody = "If the borrower is paying off the LOC, the loan purpose must be 'Refinance Cash Out'";
        this.showOkayModal = true;
        this.loan.loanPurpose = "Refinance Cash Out";
      }
    },
    changeLoanAmount: function() {
      var val = this.formatCurrency(this.loan.amount);

      this.loan.amount = this.formatCurrency(val);
    },
    changePropertyAddressZip: async function() {
      if (this.validZip(this.propertyAddress.zip)) {
        this.zipInfo = await County.getInfoByZip(this.propertyAddress.zip)

        this.cityStateZipCounty = this.zipInfo.city + ", " + this.zipInfo.shortState + ", " + this.zipInfo.county;
      } else {
        this.cityStateZipCounty = "Invalid or Blank Zip Code";
      }
    },
    changePropertyAmount: function(fieldName) {
      var val = this.formatCurrency(this.property[fieldName]);

      this.property[fieldName] = this.formatCurrency(val);
    },
    changeCredit: function() {
      var val = this.formatCurrency(this.loan.credit);

      this.loan.credit = this.formatCurrency(val);
    },
    changeRefinanceAmount: function(fieldName) {
      var val = this.formatCurrency(this.refinanceLoan[fieldName]);

      this.refinanceLoan[fieldName] = this.formatCurrency(val);
    },
    confirmAttachApplication: function(index) {
      this.confirmModalIndex = index;

      if (this.passedApplicationId > 0) {
        this.confirmModalHeader = "Please Confirm";
        this.confirmModalBody = "Please confirm you would like to update the application for "
          + this.primaryBorrower.firstName + " " + this.primaryBorrower.lastName + " with information from the quote for '"
          + this.filteredResult[index].productName + "'";

        this.showConfirmModal = true;
      } else {
        this.attachApplication();
      }
    },
    convertStringToNumber: function (val) {
      if (val == null) {
        val = 0.0;
      } else if (typeof val === 'string') {
        val = val.replace(/\D/g, '');

        if (val != "") {
          val = (+val);
        } else {
          val = 0.0;
        }
      }

      return val;
    },
    convertStringToZip: function (val) {
      if (val == null) {
        val = "";
      } else if (typeof val === 'string') {
        val = val.replace(/\D/g, '');

        if (val == '' || val.length != 5) {
          val = "";
        }
      }

      return val;
    },
    createApplication: function (applicationId) {
      this.showApplicationModal = false;
      this.routeToEdit(applicationId);
    },
    formatLenderPreview: function (lender) {
      return lender.split(' ')[0]
    },
    formatProductNamePreview: function(productName) {
      let formattedProductName = productName.split(' - ')[1];

      return (formattedProductName) ? formattedProductName : productName;
    },
    formatCurrency: function (amount, cents) {
      var retVal = numeral(amount).format('$0,0');

      if (cents) {
        retVal = numeral(amount).format('$0,0.00');
      }

      return retVal;
    },
    formatPercentage: function (amount) {
      if (amount > 1) {
        amount = amount / 100;
      }
      return numeral(amount).format('0.000%');
    },
    loadApplicationData: async function (applicationId) {
      // Application
      this.application = await Application.getApplication(applicationId);

      // Loan
      this.loan = this.application.loan;
      this.loan.amount = this.formatCurrency(this.loan.amount);
      this.loan.credit = this.formatCurrency(this.loan.credit);

      // Refinance loan
      if (typeof(this.loan.refinanceLoanId) !== 'undefined') {
        this.refinanceLoan = await RefinanceLoan.getRefinanceLoan(this.loan.refinanceLoanId);
        this.refinanceLoan.locAmount = this.formatCurrency(this.refinanceLoan.locAmount);
        this.refinanceLoan.cashOutAmount = this.formatCurrency(this.refinanceLoan.cashOutAmount);
      }

      // Property and property address
      this.property = this.application.property;
      this.property.purchasePrice = this.formatCurrency(this.property.purchasePrice);
      this.property.value = this.formatCurrency(this.property.value);
      this.propertyAddress = this.property.address;
      this.changePropertyAddressZip();

      //
      // Primary borrower
      //
      this.primaryBorrower = await Borrower.getPrimaryBorrower(applicationId);
    },
    promotionCodeValue: function (promotionCode) {
      if (promotionCode.value !== '') {
        return promotionCode.promotionCode + " (Discount: " + this.formatCurrency(promotionCode.discountAmount) + ")";
      }
    },
    search: async function() {
      var purchasePrice = this.convertStringToNumber(this.property.purchasePrice);
      var value = this.convertStringToNumber(this.property.value);

      var searchCriteria = {
        loanPurpose: this.loan.loanPurpose,
        propertyValue: (this.loan.loanPurpose !== 'Purchase') ? value : purchasePrice,
        loanAmount: this.convertStringToNumber(this.loan.amount),
        loanType: this.loan.loanType,
        lockPeriod: this.loan.lockPeriod
            ? this.loan.lockPeriod
            : null,
        amortizationType: this.loan.amortizationType
            ? this.loan.amortizationType
            : null,
        zipCode: this.convertStringToZip(this.propertyAddress.zip),
        propertyType: this.property.propertyType,
        propertyUse: this.property.propertyUse,
        creditRating: this.primaryBorrower.creditRating,
        taxesAndInsurance: this.primaryBorrower.taxesAndInsurance,
        loanRefinanceType: (this.loan.loanPurpose === 'Refinance Cash Out') ? 'Cash Out' : 'No Cash Out',
        promotionCode: this.application.promotionCode
      };

      this.showSpinnerModal = true;
      this.searchResults = await LoanSearch.loanSearch(searchCriteria);
      this.showSpinnerModal = false;

      this.quoteRun = true;
    },
    showSearchResultDetail: function(searchResult) {
      this.passedData.borrower = this.primaryBorrower;
      this.passedData.searchResult = searchResult;
      this.showSearchResultModal = true;
    },
    sort: function(column) {
      this.reverse = (this.sortColumn === column) ? ! this.reverse : false;
      this.sortColumn = column;

      let localReverse = this.reverse;
      this.searchResults.sort(function(a, b) {
        if (localReverse) {
          return b[column] - a[column];
        } else {
          return a[column] - b[column];
        }
      })
    },
    routeToEdit: function(applicationId) {
      this.$router.push({name: 'ApplicationsEdit', params: {applicationId: applicationId}});
    },
    validZip: function(zipCode) {
      var zip = this.convertStringToZip(zipCode);

      return (zip != "") ? true : false;
    }
  },

}
</script>
