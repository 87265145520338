import HTTP from './HttpService'

var ApplicationHistory = {

  createApplicationHistory: async function (applicationId) {
    var responseApplicationHistory = {};

    await HTTP.http().post('/applicationhistory/create/' + applicationId)
      .then(function(result) {
        responseApplicationHistory = result.data;
      })
      .catch(function(error) {
        console.log('Create ApplicationHistoryService error: ' + error);
      });

    return responseApplicationHistory;
  },

  getApplicationHistories: async function (applicationId) {
    let applicationHistories = null;

    await HTTP.http().get("/applicationhistory/" + applicationId)
      .then(function(result) {
        applicationHistories = result.data;
      })
      .catch(function(error) {
        console.log('Load application history list error: ' + error);
      });

    return applicationHistories;
  }
}

export default ApplicationHistory;
