import HTTP from './HttpService'

let Address = {

  getAddress: async function(addressId) {
    let address = {};

    await HTTP.http().get('/address/' + addressId)
      .then(function(response) {
        if (response) {
          address = response.data;
        }
      })
      .catch(function(error) {
        console.log('Load address error: ' + error);
      });

      return address;
  },

  createAddress: async function (addressData) {
    let responseAddress = {};

    await HTTP.http().post('/address/create', addressData)
      .then(function(result) {
        responseAddress = result.data;
      })
      .catch(function(error) {
        console.log('Create address error: ' + error);
      });

    return responseAddress;
  },

  updateAddress: async function (addressData) {
    let responseAddress = {};

    await HTTP.http().post('/address/update', addressData)
      .then(function(result) {
        responseAddress = result.data;
      })
      .catch(function(error) {
        console.log('Update address error: ' + error);
      });

      return responseAddress;
  }

}

export default Address;
