import HTTP from './HttpService'

var PromotionCode = {

  createPromotionCode: async function (promotionCode) {
    var responsePromotionCode = {};

    await HTTP.http().post('/promotioncode/create', promotionCode)
      .then(function(result) {
        responsePromotionCode = result.data;
      })
      .catch(function(error) {
        console.log('Create promotion code error: ' + error);
      });

    return responsePromotionCode;
  },

  deletePromotionCode: async function (promotionCodeId) {
    var deleteResponse = {};

    await HTTP.http().delete('/promotioncode/delete/' + promotionCodeId)
      .then(function(result) {
        deleteResponse = result.data;
      })
      .catch(function(error) {
        console.log('Delete promotion code error: ' + error);
      });

    return deleteResponse;
  },

  getAllPromotionCodes: async function() {
    var promotionCodes = [];

    await HTTP.http().get('/promotioncode/all')
      .then(function(response) {
        if (response) {
          promotionCodes = response.data;
        }
      })
      .catch(function(error) {
        console.log('Load all promotion codes error: ' + error);
      });

      return promotionCodes;
  },

  getPromotionCode: async function(promotionCodeId) {
    var promotionCode = null;

    await HTTP.http().get('/promotioncode/' + promotionCodeId)
      .then(function(response) {
        if (response) {
          promotionCode = response.data;
        }
      })
      .catch(function(error) {
        console.log('Load promotionCode error: ' + error);
      });

      return promotionCode;
  },

  getPromotionCodes: async function() {
    var promotionCodes = [];

    await HTTP.http().get('/promotioncode')
      .then(function(response) {
        if (response) {
          promotionCodes = response.data;
        }
      })
      .catch(function(error) {
        console.log('Load promotion codes error: ' + error);
      });

      return promotionCodes;
  },

  updatePromotionCode: async function (promotionCodeData) {
    let dividedDiscountPercent = promotionCodeData.discountPercent / 100;

    promotionCodeData.discountPercent = parseFloat(dividedDiscountPercent.toFixed(4));

    let responsePromotionCode = {};

    await HTTP.http().post('/promotioncode/update', promotionCodeData)
      .then(function(result) {
        responsePromotionCode = result.data;
      })
      .catch(function(error) {
        console.log('Update promotion code error: ' + error);
      });

      return responsePromotionCode;
  }

}

export default PromotionCode;
