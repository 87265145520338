<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header font-weight-bold">
            <slot name="header">Loan Detail for '{{passedData.searchResult.productName}}' for {{passedData.borrower.firstName}} {{passedData.borrower.lastName}}</slot>
          </div>

          <div class="modal-body mt-0 mb-0">
            <form>

              <div class="form-row ml-2 mr-2">
                <div class="form-group col-md-4">
                  <label for="investor" class="text-dark">Investor</label>
                  <br />
                  <label id="investor">{{passedData.searchResult.investor}}</label>
                </div>
                <div class="form-group col-md-8">
                  <label for="productName" class="text-dark">Product Name</label>
                  <br />
                  <label id="productName">{{passedData.searchResult.productName}}</label>
                </div>
              </div>

              <div class="form-row ml-2 mr-2">
                <div class="form-group col-md-3">
                  <label for="rate" class="text-dark">Rate</label>
                  <br />
                  <label id="rate">{{formatPercentage(passedData.searchResult.rate)}}</label>
                </div>
                <div class="form-group col-md-3">
                  <label for="apr" class="text-dark">APR</label>
                  <br />
                  <label id="apr">{{formatPercentage(passedData.searchResult.apr)}}</label>
                </div>
                <div class="form-group col-md-3">
                  <label for="loanTerm" class="text-dark">Loan Term</label>
                  <br />
                  <label id="loanTerm">{{passedData.searchResult.amortizationTerm}}</label>
                </div>
                <div class="form-group col-md-3">
                  <label for="amortizationType" class="text-dark">Amortization Type</label>
                  <br />
                  <label id="amortizationType">{{passedData.searchResult.amortizationType}}</label>
                </div>
              </div>

              <div class="form-row ml-2 mr-2">
                <div class="form-group col-md-3">
                  <label for="price" class="text-dark">Price</label>
                  <br />
                  <label id="price">{{passedData.searchResult.price}}</label>
                </div>
                <div class="form-group col-md-3">
                  <label for="fee" class="text-dark">Fee</label>
                  <br />
                  <label id="fee">{{formatCurrency(passedData.searchResult.fee)}}</label>
                </div>
                <div class="form-group col-md-6">
                  <label for="recommended" class="text-dark">Recommended</label>
                  <br />
                  <label id="recommended">{{passedData.searchResult.recommended ? "True" : "False"}}</label>
                </div>
              </div>

              <div class="form-row ml-2 mr-2">
                <div class="form-group col-md-4">
                  <label for="principalAndInterest" class="text-dark">Principal and Interest</label>
                  <br />
                  <label id="principalAndInterest">{{formatCurrency(passedData.searchResult.principalAndInterest)}}</label>
                </div>
                <div class="form-group col-md-4">
                  <label for="mortgageInsurance" class="text-dark">Mortgage Insurance</label>
                  <br />
                  <label id="mortgageInsurance">{{formatCurrency(passedData.searchResult.monthlyMortgageInsurance)}}</label>
                </div>
                <div class="form-group col-md-4">
                  <label for="totalPayment" class="text-dark">Total Payment</label>
                  <br />
                  <label id="totalPayment">{{formatCurrency(passedData.searchResult.totalPayment)}}</label>
                </div>
              </div>

              <div class="form-row ml-2 mr-2">
                <div class="col-md-12"><hr></div>
              </div>

              <div class="form-row ml-2 mr-2">
                <div class="form-group col-md-4">
                  <label for="promotionalFee" class="text-dark">Promotional Fee</label>
                  <br />
                  <label id="promotionalFee">{{passedData.searchResult.promotionalFee > 0 ? formatCurrency(passedData.searchResult.promotionalFee) : ""}}</label>
                </div>
                <div class="form-group col-md-8">
                  <label for="promotionalMessage" class="text-dark">Promotional Message</label>
                  <br />
                  <label id="promotionalMessage">{{passedData.searchResult.displayMessage}}</label>
                </div>
              </div>

              <div class="form-row ml-2 mr-2">
                <div class="col-md-12"><hr></div>
              </div>

              <div class="form-row ml-2 mr-2">
                <div class="form-group col-md-4">
                  <label for="productId" class="text-dark">OB Product ID</label>
                  <br />
                  <label id="productId">{{passedData.searchResult.productId}}</label>
                </div>
                <div class="form-group col-md-4">
                  <label for="productCode" class="text-dark">OB Product Code</label>
                  <br />
                  <label id="productCode">{{passedData.searchResult.productCode}}</label>
                </div>
              </div>

            </form>
          </div>

          <div class="modal-footer">
            <div class="col-auto">
              <div class="container mt-2">
                <div class="row">
                  <div class="col">
                    <button class="btn btn-secondary mt-2" @click.prevent="close()">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import numeral from 'numeral'

export default {
  name: 'searchResultModal',
  methods: {
    close: function () {
      this.$emit('close');
    },
    formatCurrency: function (amount) {
      return numeral(amount).format('$0,0');
    },
    formatPercentage: function (amount) {
      if (amount > 1) {
        amount = amount / 100;
      }
      return numeral(amount).format('0.000%');
    },
  },
  props: ['passedData'],
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 900px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #18752E;
}

.modal-body {
  margin: 20px 0;
  color: #18752E;
}

.modal-footer {
  color: #18752E;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
