import HTTP from './HttpService'

var RefinanceLoan = {

  getRefinanceLoan: async function(refinanceLoanId) {
    var refinanceLoan = {};

    await HTTP.http().get('/refinanceloan/' + refinanceLoanId)
      .then(function(response) {
        if (response) {
          refinanceLoan = response.data;
        }
      })
      .catch(function(error) {
        console.log('Load refinance loan error: ' + error);
      });

      return refinanceLoan;
  },

  createRefinanceLoan: async function (refinanceLoanData) {
    var responseRefinanceLoan = {};

    await HTTP.http().post('/refinanceloan/create', refinanceLoanData)
      .then(function(result) {
        responseRefinanceLoan = result.data;
      })
      .catch(function(error) {
        console.log('Create refinance loan error: ' + error);
      });

    return responseRefinanceLoan;
  },

  deleteRefinanceLoan: async function (refinanceLoanId) {
    var deleteResponse = {};

    await HTTP.http().delete('/refinanceloan/delete/' + refinanceLoanId)
      .then(function(result) {
        deleteResponse = result.data;
      })
      .catch(function(error) {
        console.log('Delete refinance loan error: ' + error);
      });

    return deleteResponse;
  },

  updateRefinanceLoan: async function (refinanceLoanData) {
    var responseRefinanceLoan = {};

    await HTTP.http().post('/refinanceloan/update', refinanceLoanData)
      .then(function(result) {
        responseRefinanceLoan = result.data;
      })
      .catch(function(error) {
        console.log('Update refinance loan error: ' + error);
      });

      return responseRefinanceLoan;
  }

}

export default RefinanceLoan;
