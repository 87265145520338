import HTTP from './HttpService'

var County = {

  getInfoByZip: async function(zipCode) {
    var reply = {};

    await HTTP.http().get('/county/zip/' + zipCode)
      .then(function(response) {
        if (response) {
          reply = response.data;
        }
      })
      .catch(function(error) {
        console.log('Load error for county lookup for "' + zipCode + '": ' + error);
      });

      return reply;
  },

}

export default County;
