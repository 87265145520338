import HTTP from './HttpService'

var Note = {

  getNotes: async function(applicationId) {
    var notes = [];

    await HTTP.http().get('/note/applicationid/' + applicationId)
      .then(function(response) {
        if (response) {
          notes = response.data;
        }
      })
      .catch(function(error) {
        console.log('Load notes error: ' + error);
      });

      return notes;
  },

  createNote: async function (noteData) {
    var responseNote = {};

    await HTTP.http().post('/note/create', noteData)
      .then(function(result) {
        responseNote = result.data;
      })
      .catch(function(error) {
        console.log('Create note error: ' + error);
      });

    return responseNote;
  },

  deleteNote: async function (noteId) {
    var deleteResponse = {};

    await HTTP.http().delete('/note/delete/' + noteId)
      .then(function(result) {
        deleteResponse = result.data;
      })
      .catch(function(error) {
        console.log('Delete note error: ' + error);
      });

    return deleteResponse;
  },

  updateNote: async function (noteData) {
    var responseNote = {};

    await HTTP.http().post('/note/update', noteData)
      .then(function(result) {
        responseNote = result.data;
      })
      .catch(function(error) {
        console.log('Update note error: ' + error);
      });

      return responseNote;
  }

}

export default Note;
