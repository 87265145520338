import HTTP from './HttpService'

var LoanSearch = {

  loanSearch: async function (searchCriteria) {
    var searchResults = [];

    await HTTP.http().post('/loansearch', searchCriteria)
      .then(function(result) {
        searchResults = result.data;
      })
      .catch(function(error) {
        console.log('Loan search error: ' + error);
      });

    return searchResults;
  },

}

export default LoanSearch;
