import HTTP from './HttpService'

var Property = {

  getProperty: async function(propertyId) {
    var property = {};

    await HTTP.http().get('/property/' + propertyId)
      .then(function(response) {
        if (response) {
          property = response.data;
        }
      })
      .catch(function(error) {
        console.log('Load property error: ' + error);
      });

      return property;
  },

  createProperty: async function (propertyData) {
    var responseProperty = {};

    await HTTP.http().post('/property/create', propertyData)
      .then(function(result) {
        responseProperty = result.data;
      })
      .catch(function(error) {
        console.log('Create property error: ' + error);
      });

    return responseProperty;
  },
  setAddress: async function (id, address) {
    let responseAddress = {};
    await HTTP.http().put('/property/' + id + '/address/' + address)
        .then(function(result) {
          responseAddress = result.data;
        })
        .catch(function(error) {
          console.log('setAddress error: ' + error);
        });

    return responseAddress;
  },

  setState: async function (id, stateId) {
    let responseAddress = {};
    await HTTP.http().put('/property/' + id + '/state/' + stateId)
        .then(function(result) {
          responseAddress = result.data;
        })
        .catch(function(error) {
          console.log('setAddress error: ' + error);
        });

    return responseAddress;
  },

  setCity: async function (id, city) {
    let responseAddress = {};
    await HTTP.http().put('/property/' + id + '/city/' + city)
        .then(function(result) {
          responseAddress = result.data;
        })
        .catch(function(error) {
          console.log('setAddress error: ' + error);
        });

    return responseAddress;
  },

  setZipCode: async function (id, zipCode) {
    let responseAddress = {};
    await HTTP.http().put('/property/' + id + '/zipcode/' + zipCode)
        .then(function(result) {
          responseAddress = result.data;
        })
        .catch(function(error) {
          console.log('setAddress error: ' + error);
        });

    return responseAddress;
  },

  updateProperty: async function (propertyData) {
    var responseProperty = {};

    await HTTP.http().post('/property/update', propertyData)
      .then(function(result) {
        responseProperty = result.data;
      })
      .catch(function(error) {
        console.log('Update property error: ' + error);
      });

      return responseProperty;
  }

}

export default Property;
