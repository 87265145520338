<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header font-weight-bold">
            <slot name="header">Create New Application</slot>
          </div>

          <div class="modal-body">
            <form class="ml-2 mr-2" @submit.prevent="create">
              <div class="form-row">
                <div class="form-group col-md-12">

                  <div class="form-row ml-2 mr-2">
                    <div class="form-group col-md-6">
                      <label for="firstName" class="form-control-sm text-dark">Borrower First Name</label>
                      <span class="input-help">
                        <input type="text" class="form-control form-control-sm" id="firstName" placeholder="Borrower First Name" v-model="formData.borrower.firstName"/>
                        <small id="firstNameHelpBlock" class="ml-2 form-text text-danger" v-if="checkForBlank(formData.borrower.firstName)">First name is required</small>
                      </span>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="lastName" class="form-control-sm text-dark">Borrower Last Name</label>
                      <span class="input-help">
                        <input type="text" class="form-control form-control-sm" id="lastName" placeholder="Borrower Last Name" v-model="formData.borrower.lastName"/>
                        <small id="lastNameHelpBlock" class="ml-2 form-text text-danger" v-if="checkForBlank(formData.borrower.lastName)">Last name is required</small>
                      </span>
                    </div>
                  </div>

                  <div class="form-row ml-2 mr-2">
                    <div class="form-group col-md-5">
                      <label for="cellPhone" class="form-control-sm text-dark">Cell Phone</label>
                      <span class="input-help">
                        <input
                          type="tel"
                          class="form-control form-control-sm"
                          id="cellPhone"
                          placeholder="Cell Phone"
                          v-model="formData.borrower.cellPhone"
                          @keyup="changePhone('cellPhone')" />
                        <small id="phoneHelpBlock" class="ml-2 form-text text-danger" v-if="checkPhonesForBlank()">One number is required</small>
                      </span>
                    </div>
                    <div class="form-group col-md-7">
                      <label for="email" class="form-control-sm text-dark">Email</label>
                      <span class="input-help">
                        <input type="email" class="form-control form-control-sm" id="email" placeholder="Email Address" v-model="formData.borrower.email"/>
                        <small id="emailHelpBlock" class="ml-2 form-text text-danger" v-if="checkEmailForBlank(formData.borrower.email)">Email is required</small>
                      </span>
                    </div>
                  </div>

                  <div class="form-row ml-2 mr-2">
                    <div class="form-group col-md-5">
                      <label for="userId" class="form-control-sm text-dark">Consultant</label>
                      <select class="custom-select custom-select-sm" id="userId" v-model="formData.application.userId">
                        <option v-for="(user) in userList" :key="user.id" :value="user.id">
                          {{user.firstName}} {{user.lastName}} ({{user.username}})
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-7">
                      <label for="leadSource" class="form-control-sm text-dark">Lead Source</label>
                      <select class="custom-select custom-select-sm" id="leadSource" v-model="formData.lead.leadSource">
                        <option value=null disabled hidden>CHOOSE ONE</option>
                        <option v-for="(leadSource) in leadSources" :key="leadSource.id" :value="leadSource.value">
                          {{leadSource.value}}
                        </option>
                      </select>
                      <small id="leadSourceHelpBlock" class="ml-2 form-text text-danger" v-if="checkLeadSourceForBlank()">Lead Source is required</small>
                    </div>
                  </div>

                </div>
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <div class="col-auto">
              <div class="container mt-2">
                <div class="row">
                  <div class="col">
                    <button class="btn btn-primary mt-2" @click.prevent="create()">Create</button>
                  </div>
                  <div class="col">
                    <button class="btn btn-secondary mt-2" @click.prevent="cancel()">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import numeral from 'numeral'

import Application from '@/services/ApplicationService'
import PickDetail from '@/services/PickDetailService'
import PromotionCode from '@/services/PromotionCodeService'
import Users from '@/services/UserService'
import Store from "@/store";

export default {
  name: 'applicationModal',
  computed: {
    showPropertyValue: function() {
      return this.formData.loan.loanPurpose !== 'Purchase';
    },
    showPurchasePrice: function () {
      return this.formData.loan.loanPurpose === 'Purchase';
    },
    currentUserId: function() {
      return (Store.state.userInfo != null) ? Store.state.userInfo.id : "";
    },
  },
  created: async function () {
    // Get data from our APIs

    // Loan purposes
    this.loanPurposes = await PickDetail.getPickListDetail('loanpurpose');

    // Promotion codes
    this.promotionCodes = await PromotionCode.getPromotionCodes();

    var blankPromotionCode = {
      value: '',
    };

    this.promotionCodes.unshift(blankPromotionCode);

    // User list
    this.userList = await Users.getUserList();

    this.leadSources = await PickDetail.getPickListDetail("leadsource");

    // Default user is current user
    this.formData.application.userId = this.currentUserId;

    // If we were passed data, load it into formData
    if (this.passedData.dataWasPassed) {
      this.formData.loan.amount = this.passedData.loanAmount;
      this.formData.property.purchasePrice = this.passedData.purchasePrice;
      this.formData.property.value = this.passedData.value;
      this.formData.loan.loanPurpose = this.passedData.loanPurpose;
      this.formData.application.promotionCode = this.passedData.promotionCode;
    }
  },
  data: function () {
    return {
      formData: {
        application: {},
        lead: {
          leadSource: null
        },
        borrower: {
          firstName: "",
          lastName: "",
          homePhone: "",
          cellPhone: "",
          email: ""
        },
        coBorrower: {
          firstName: "",
          lastName: "",
        },
        loan: {},
        property: {
          value: "",
          purchasePrice: "",
        },
      },
      loanPurposes: [],
      promotionCodes: [],
      showHelp: false,
      showHelpEmail: false,
      userList: [],
      leadSources: []
    }
  },
  methods: {
    cancel: function () {
      this.$emit('cancel');
    },
    changeLoanAmount: function() {
      let val = this.formatCurrency(this.formData.loan.amount);

      this.formData.loan.amount = this.formatCurrency(val);
    },
    changePropertyAmount: function(fieldName) {
      let val = this.formatCurrency(this.formData.property[fieldName]);

      this.formData.property[fieldName] = this.formatCurrency(val);
    },
    changePhone: function(fieldName) {
      let value = this.formData.borrower[fieldName];

      // Strip all characters from the input except digits and trim to ten characters
      value = value.replace(/\D/g,'');
      value = value.substring(0,10);

      // Format based on the length of the string
      let size = value.length;

      if (size === 0) {
        // NOP
      } else if (size < 4) {
        value = '(' + value;
      } else if (size < 7) {
        value = '(' + value.substring(0,3) + ') ' + value.substring(3,6);
      } else {
        value = '(' + value.substring(0,3) + ') ' + value.substring(3,6) + '-' + value.substring(6,10);
      }

      this.formData.borrower[fieldName] = value;
    },
    checkForBlank: function(fieldValue) {
      return (this.showHelp && fieldValue === '');
    },
    checkEmailForBlank: function(fieldValue) {
      return ((this.showHelp || this.showHelpEmail) && fieldValue === '');
    },
    checkPhonesForBlank: function() {
      return (this.showHelp && this.formData.borrower.homePhone === '' && this.formData.borrower.cellPhone === '');
    },
    checkLeadSourceForBlank: function() {
      return (this.showHelp && this.formData.lead.leadSource === null);
    },
    convertString: function (val) {
      if (val == null) {
        val = 0.0;
      } else if (typeof val === 'string') {
        val = val.replace(/\D/g, '');

        if (val != "") {
          val = (+val);
        } else {
          val = 0.0;
        }
      }

      return val;
    },
    create: async function() {
      if (this.formData.borrower.firstName === ''
        || this.formData.borrower.lastName === ''
        || (this.formData.borrower.homePhone === '' && this.formData.borrower.cellPhone === '')
        || this.formData.borrower.email === ''
        || this.formData.lead.leadSource === null
      ) {
        this.showHelp = true;
      } else {
        let application = this.createApplicationObject();

        let newApplication = await Application.createApplication(application);
        await Application.setLeadSource(newApplication.id, this.formData.lead.leadSource);

        console.log('new application id: ' + newApplication.id);
        this.$emit("create", newApplication.id);
      }
    },
    formatCurrency: function (amount) {
      return numeral(amount).format('$0,0');
    },
    promotionCodeValue: function (promotionCode) {
      if (promotionCode.value !== '') {
        return promotionCode.promotionCode + " (Discount Amount: " + this.formatCurrency(promotionCode.discountAmount) + ")";
      }
    },
    createApplicationObject: function() {
      return {
        userId: this.formData.application.userId,
        borrowers: this.createBorrowersObject(),
        donationAmount: 0.00,
        loan: this.createLoanObject(),
        investor: (this.passedData.investor != null) ? this.passedData.investor : "",
        product: (this.passedData.product != null) ? this.passedData.product : "",
        productId: (this.passedData.productId != null) ? this.passedData.productId : 0,
        promotionCode: this.formData.application.promotionCode,
        property: this.createPropertyObject(),
        result: (this.passedData.result != null) ? this.passedData.result : "Manually created application from Admin interface.",
      };
    },
    createBorrowersObject: function() {
      let borrowers = [];

      borrowers.push({
        borrowerType: "Primary",
        creditRating: this.passedData.creditRating,
        cellPhone: this.formData.borrower.cellPhone,
        email: this.formData.borrower.email,
        firstName: this.formData.borrower.firstName,
        homePhone: this.formData.borrower.homePhone,
        lastName: this.formData.borrower.lastName,
        taxesAndInsurance: this.passedData.taxesAndInsurance
      });

      if (this.formData.coBorrower.firstName !== "" || this.formData.coBorrower.lastName !== "") {
        borrowers.push({
          borrowerType: "Co Borrower",
          email: this.formData.borrower.email,
          firstName: (this.formData.coBorrower.firstName != null) ? this.formData.coBorrower.firstName : "",
          lastName: (this.formData.coBorrower.lastName != null) ? this.formData.coBorrower.lastName : "",
        });
      }

      return borrowers;
    },
    createLoanObject: function() {
      return {
        amortizationType: this.passedData.amortizationType,
        loanType: this.passedData.loanType,
        lockPeriod: this.passedData.lockPeriod,
        amount: this.convertString(this.formData.loan.amount),
        cashOutAmount: this.convertString(this.passedData.cashOutAmount),
        loanPurpose: this.formData.loan.loanPurpose,
        loanImpounds: this.passedData.loanImpounds,
        mortgagePayment: this.passedData.totalPayment,
        numberOfMonths: this.passedData.numberOfMonths,
        interestRate: this.passedData.rate,
        apr: this.passedData.apr,
        credit: this.formData.loan.credit,
        fee: this.passedData.fee,
        totalThirdPartyFee: this.passedData.totalThirdPartyFee,
        loc: this.passedData.loc,
        locAmount: this.convertString(this.passedData.locAmount),
        keepingLoc: this.passedData.keepingLoc,
      };
    },
    createPropertyObject: function() {
      return {
        address: "",
        numberUnits: 1,
        propertyType: this.passedData.propertyType,
        propertyUse: this.passedData.propertyUse,
        purchasePrice: this.convertString(this.formData.property.purchasePrice),
        value: this.convertString(this.formData.property.value),
        zip: (this.passedData.zip != null) ? this.passedData.zip : "",
      };
    }
  },
  props: ['passedData'],
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 700px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #18752E;
}

.modal-body {
  margin: 20px 0;
  color: #18752E;
}

.modal-footer {
  color: #18752E;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
