import HTTP from './HttpService'

var Borrower = {

  createBorrower: async function (borrower) {
    var createResponse = {};

    await HTTP.http().post('/borrower/create', borrower)
      .then(function(result) {
        createResponse = result.data;
      })
      .catch(function(error) {
        console.log('Create borrower error: ' + error);
      });

    return createResponse;
  },

  createBorrowerAddress: async function (borrower, address) {
    var createResponse = {};

    await HTTP.http().post('/borrower/createaddress/' + borrower.id, address)
      .then(function(result) {
        createResponse = result.data;
      })
      .catch(function(error) {
        console.log('Create borrower address error: ' + error);
      });

    return createResponse;
  },

  deleteBorrower: async function (borrowerId) {
    var deleteResponse = {};

    await HTTP.http().delete('/borrower/delete/' + borrowerId)
      .then(function(result) {
        deleteResponse = result.data;
      })
      .catch(function(error) {
        console.log('Delete borrower error: ' + error);
      });

    return deleteResponse;
  },

  deleteBorrowerAddress: async function (borrowerId, addressId) {
    var deleteResponse = {};

    await HTTP.http().delete('/borrower/deleteaddress/' + borrowerId + '/' + addressId)
      .then(function(result) {
        deleteResponse = result.data;
      })
      .catch(function(error) {
        console.log('Delete borrower address error: ' + error);
      });

    return deleteResponse;
  },

  getBorrowerAddresses: async function (borrowerId) {
    var addressData = [];

    await HTTP.http().get('/borrower/addresses/' + borrowerId)
      .then(function(result) {
        addressData = result.data;
      })
      .catch(function(error) {
        console.log('Load borrowers addresses error: ' + error);
      });

    return addressData;
  },

  getBorrowers: async function (applicationId) {
    var borrowerData = [];

    await HTTP.http().get('/borrower/applicationid/' + applicationId)
      .then(function(result) {
        borrowerData = result.data;
      })
      .catch(function(error) {
        console.log('Load borrowers error: ' + error);
      });

    return borrowerData;
  },

  getBorrower: async function (borrowerId) {
    var borrower = {};

    await HTTP.http().get("/borrower/" + borrowerId)
      .then(function(result) {
        borrower = result.data;
      })
      .catch(function(error) {
        console.log('Load single borrower error: ' + error);
      });

    return borrower;
  },

  getPrimaryBorrower: async function (applicationId) {
    var borrower = {};

    await HTTP.http().get("/borrower/primary/" + applicationId)
      .then(function(result) {
        borrower = result.data;
      })
      .catch(function(error) {
        console.log('Load single borrower error: ' + error);
      });

    return borrower;
  },

  updateBorrower: async function (borrower) {
    var updateResult = {};

    await HTTP.http().post('/borrower/update', borrower)
      .then(function(result) {
        updateResult = result.data;
      })
      .catch(function(error) {
        console.log('Update borrower error: ' + error);
      });

    return updateResult;
  },

  updateCreditRating: async function (borrowerId, creditRating) {
    let result = {};

    await HTTP.http().put('/borrower/' + borrowerId + '/creditrating/' + encodeURIComponent(creditRating))
        .then(function (b) {
          result = b.data;
        })
        .catch(function (error) {
          console.log('update credit rating error: ' + error);
        });
    return result;
  },

}

export default Borrower;
